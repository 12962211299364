import React from "react"
import Image from "gatsby-image"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const ImageGalleryWithLightbox = (props) => {

  const images = props.images.edges == null ? props.images : props.images.edges

  return (
    <SimpleReactLightbox>
        <SRLWrapper>
          <div className={props.className}>
            {images.map((element) => (
                <Image
                fluid={element.node == null ? element : element.node.childImageSharp.fluid}
                style={{marginBottom: '1rem', cursor: 'pointer'}}
                />
            ))}
          </div>
        </SRLWrapper>
    </SimpleReactLightbox>    
  )
}

export default ImageGalleryWithLightbox
